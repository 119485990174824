@import '~styles/palette';

.wrap {
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 18px;
  padding: 14px 16px;
  font-size: 14px;
  line-height: 1.71;
  color: palette(black);
  letter-spacing: 0.01em;
  background: palette(gray10);
  border-radius: 8px;

  &.subscription {
    margin: -4px 12px 0;
  }

  &:not(.warning, .cardWarning) {
    svg {
      color: palette(green60);
    }
  }

  &.warning {
    column-gap: 6px;
    padding: 12px;
    margin-top: 8px;
    font-size: 18px;
    line-height: 1.3;
    color: palette(orange70);
    background-color: palette(orange10);
  }

  &.cardWarning {
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 1;
    column-gap: 8px;
    padding: 12px;
    font-weight: 500;
    color: palette(orange70);
    text-align: right;
    user-select: none;
    background-color: palette(orange10);
    border-radius: 24px;
  }
}

.content {
  > span {
    text-transform: lowercase;
  }
}
