/* stylelint-disable no-descending-specificity */
@import '~styles/palette';

.cell {
  height: 27px;
  font-size: 14px;
  font-weight: normal;
  line-height: 24px;
  color: palette(gray80);
  text-align: center;
  letter-spacing: 0.14px;
  user-select: none;
  border-radius: 4px;
  transition: all 250ms;

  &:hover,
  &:focus {
    background: palette(gray20);
    outline: none;
  }

  &.active {
    color: palette(white);
    background: palette(green60);
  }

  &:disabled {
    pointer-events: none;
    opacity: 0.4;
  }

  &.quarter {
    position: relative;
    height: 24px;
    letter-spacing: 0.01em;
  }
}
