.badge {
  $this: &;

  line-height: 1.71;
  letter-spacing: 0.06em;
  vertical-align: middle;

  &:not(#{$this}-export, #{$this}-reportFormat) {
    text-transform: uppercase;
  }

  @each $name, $colors in $badge-colors {
    &-#{$name} {
      color: map-get($colors, color);
      background-color: map-get($colors, bg);
    }
  }

  &-xs {
    display: grid;
    grid-template-columns: repeat(2, auto);
    grid-column-gap: 4px;
    align-items: center;
    min-height: 24px;
    padding: 0 8px;
    text-transform: none;
  }

  &-sm {
    padding: 4px 13px;
    line-height: 1.72;
    letter-spacing: 0.84px;
  }

  &-md {
    display: block;
    width: fit-content;
    padding: 4px 12px 5px;
    font-size: 22px;
    font-weight: 500;
    line-height: 29px;
    letter-spacing: 0.02em;
  }

  &-lg {
    padding: 5px 16px 4px;
    color: palette(gray80);
    border-radius: 20px;
  }

  &-export {
    display: inline-grid;
    grid-template-columns: 16px auto;
    column-gap: 6px;
    align-items: center;
    justify-items: center;
    padding: 4px 8px 4px 4px;
    font-size: 12px;
    line-height: 1.333;
    letter-spacing: 0.01em;
  }

  &-subscriptions {
    padding: 6px 16px;
    border-radius: 32px;
  }

  &-reportFormat {
    display: inline-flex;
    align-items: center;
    padding: 2px 4px 2px 2px;
    margin-left: 4px;
    font-size: 10px;
    line-height: 1.4;
    letter-spacing: 0.01em;
    border-radius: 12px;

    & + & {
      margin-left: 2px;
    }
  }
}
