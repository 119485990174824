@import '~styles/palette';

.page {
  display: grid;
  grid-template-rows: repeat(4, 1fr);
  grid-template-columns: repeat(3, 81px);
  row-gap: 9px;
  column-gap: 29px;
  align-items: center;
}

.header {
  display: grid;
  row-gap: 10px;
  width: 100%;
  margin-bottom: 23px;
  text-align: center;
}

.headerDescr {
  font-size: 14px;
  font-weight: 500;
  line-height: 19px;
  color: palette(gray70);
  letter-spacing: 0.1px;
}

.headerInner {
  display: grid;
  grid-template-columns: 32px 1fr 1fr 32px;
  align-items: center;
  text-align: center;
}

.headerButton {
  display: flex;
  justify-content: center;
  color: palette(green60);
  transition: all 250ms;

  &:hover,
  &:focus {
    color: palette(green40);
  }

  &:disabled {
    color: palette(green10);
  }

  &.left {
    transform: scaleX(-1);
  }
}

.headerYear {
  font-weight: 500;
  line-height: 24px;
  color: palette(gray90);
  letter-spacing: 0.06em;
}

.pagesWrap {
  display: grid;
  grid-auto-flow: column;
  column-gap: 55px;
}
