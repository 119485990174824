/* stylelint-disable no-descending-specificity */

.page {
  &-item {
    min-width: 32px;
    min-height: 32px;
    margin: 0 8px;
    text-align: center;
  }

  &-link {
    border-radius: 4px;

    &--arrow {
      display: grid;
      grid-auto-flow: column;
      column-gap: 8px;
      align-items: center;
      font-size: 14px;
      font-weight: 500;
      color: palette(gray80);
      text-transform: uppercase;
      letter-spacing: 0.06em;

      &:hover,
      &:focus {
        color: palette(green70);
        border-color: transparent;
      }
    }

    &--next svg {
      transform: scaleX(-1);
    }

    &.disabled {
      color: palette(gray40);
      pointer-events: none;
    }
  }
}
