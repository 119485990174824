@import '~styles/palette.scss';

.list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 3px;
  column-gap: 6px;
  padding: 0;
  margin: 0;
}

.listItem {
  display: grid;
  grid-template-columns: 8px 1fr;
  column-gap: 8px;
  align-items: center;
  font-size: 14px;
  line-height: 1.43;
  color: palette(gray80);
  text-align: left;
  letter-spacing: 0.01em;
  list-style-type: none;

  &::before {
    display: inline-block;
    width: 8px;
    height: 8px;
    content: '';
    background-color: palette(gray30);
    border-radius: 2px;
    transition: background-color 200ms;
  }

  &.active::before {
    background-color: palette(green50);
  }
}
