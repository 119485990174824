@import '~styles/palette';

.modal {
  min-width: 1160px;
  border-radius: 12px;
}

.closeButton {
  position: absolute;
  top: 16px;
  right: -48px;
  color: palette(white);
  transition: transform 250ms;

  &:hover,
  &:focus {
    outline: none;
    transform: rotate(90deg);
  }
  @media screen and (max-width: 1300px) {
    right: 16px;
    color: palette(gray50);
    transition: color 200ms;

    &:hover,
    &:focus {
      color: palette(gray60);
      transform: none;
    }
  }
}
