@import '~styles/palette';

.textarea {
  padding: 0;
  font-weight: inherit;
  letter-spacing: inherit;
  resize: none;

  &:focus-visible {
    outline: none;
  }
}

.inputSizer {
  display: inline-grid;
  align-items: stretch;
  vertical-align: top;

  &::after {
    white-space: pre-wrap;
    visibility: hidden;
    content: attr(data-value) ' '; // ' ' is needed to avoid bugs
  }

  &::after,
  .textarea {
    grid-area: 2 / 1;
    overflow: hidden; // to hide scrollbar that causes a bug
  }

  // Tracker Builder
  &.divider {
    font-size: 28px;
    font-weight: 700;
    line-height: 1.28;
    letter-spacing: 0.02em;

    &::after,
    .textarea {
      min-height: 38px;
    }

    .textarea {
      color: palette(green70);
      background-color: transparent;
      border: none;
      transition: border-color 0.15s;

      &:hover {
        border-bottom-color: palette(gray50);
      }

      &:focus-visible {
        border-bottom-color: palette(gray60);
      }
    }
  }
}
