@import '~styles/palette.scss';

.loading {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 3;
  width: 100%;
  height: 100%;
  cursor: progress;
  background: rgba(palette(gray10), 0.8);
}
