/* stylelint-disable no-descending-specificity */
.dropdown {
  $this: &;

  &-menu {
    display: block;
    visibility: hidden;
    box-shadow: $dropdown-box-shadow;
    opacity: 0;
    transition: visibility 200ms, opacity 200ms;

    &-search {
      inset: 0 auto auto 0 !important;
      z-index: 1;
      padding-top: 49px;
      transform: none !important;

      #{$this}-item:first-child {
        border-top: 1px solid palette(gray30);
        border-radius: 0;
      }
    }

    &.show {
      visibility: visible;
      opacity: 1;
    }
  }

  &-item {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 500;
    line-height: calc(22 / 18);
    transition: color 0.25s;

    &:not(:first-child) {
      &::before {
        position: absolute;
        top: -0.5px;
        right: $dropdown-item-padding-x;
        left: $dropdown-item-padding-x;
        z-index: 2;
        height: 1px;
        content: '';
        background-color: palette(gray20);
      }
    }
  }
}
