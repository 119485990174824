/* stylelint-disable selector-pseudo-class-no-unknown */
@use 'sass:list';

$colors: (
  // General
  white: #fff,
  milky: #f7f9fa,
  black: #000007,
  bodybg: #f8f9fa,
  greentext: #013506,
  orangetext: #461604,
  // Clear colors
  lightblue: #3bc9db,
  orange: #febe10,
  green: #94d82d,
  pink: #f783ac,
  blue: #2c60ad,
  yellow: #ffc107,
  purple: #a138e1,
  violet: #8d84fd,
  flatblue: #6ca7ff,
  softmagenta: #cc5de8,
  softred: #ff6d6d,
  brown: #c6816c,
  darkgreen: #75a729,
  pinkgold: #ffa49e,
  teal: #289c87,
  // Colors bootstrap
  pinkb: #e83e8c,
  redb: #dc3545,
  cyanb: #17a2b8,
  // Purple
  purple20: #d2c2f0,
  purple30: #908ab5,
  purple80: #4c278c,
  // Gray
  gray10: #f5f8fa,
  gray20: #e9edf0,
  gray30: #d7dce0,
  gray35: #d5dbe0,
  gray40: #c4cacf,
  gray45: #afb7bd,
  gray50: #98a0a6,
  gray60: #80868c,
  gray70: #656d73,
  gray80: #424a4f,
  gray90: #292f33,
  gray100: #131719,
  gray200: #e9ecef,
  gray300: #596066,
  gray400: #343a40,
  gray500: #abb4ba,
  gray600: #1a1f21,
  // Green
  green10: #ddf4ec,
  green20: #b6e8d8,
  green30: #8fdcc3,
  green40: #68d0ae,
  green50: #41c499,
  green60: #36ad86,
  green70: #2e9372,
  green80: #26795e,
  green90: #1e5f4a,
  green100: #1c5945,
  // Orange
  orange10: #ffeee8,
  orange20: #fed6c8,
  orange30: #fcaf93,
  orange40: #f49a79,
  orange50: #fa7b4d,
  orange60: #f56b38,
  orange70: #e36334,
  // Violet
  negative10: #ede7f6,
  negative30: #b39ddb,
  negative40: #9575cd,
  negative50: #7e57c2,
  negative60: #7949ce,
  negative70: #673ab7
);

@function palette($key) {
  @return map-get($colors, $key);
}

@function get-multiple-colors($keys...) {
  $result: ();

  @each $key in $keys {
    $result: list.append($result, map-get($colors, $key));
  }

  @return $result;
}

:export {
  @each $name, $color in $colors {
    #{"" + $name}: #{$color};
  }
}
