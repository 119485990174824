@import '~styles/palette';

.s360_error {
  display: grid;
  place-content: center;
  justify-items: center;
  max-width: 751px;
  min-height: calc(100vh - 100px);
  font-size: 22px;
  font-weight: 500;
  line-height: 29px;
  text-align: center;
  letter-spacing: 0.02em;

  &_logo {
    max-width: 280px;
  }

  &_desc {
    margin-top: 34px;
    color: palette(gray70);
  }

  &_link {
    display: block;
    margin-top: 50px;
    color: palette(green70);
    text-decoration-line: underline;
  }
}
