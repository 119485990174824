@import '~styles/palette';

.menu {
  position: absolute;
  right: 0;
  bottom: 64px;
  min-width: 440px;
  visibility: hidden;
  background: palette(white);
  border-radius: 12px;
  box-shadow: 0 22px 47px rgba(0, 0, 0, 13%),
    0 8.4741px 14.9704px rgba(0, 0, 0, 7.9%),
    0 1.7926px 3.8296px rgba(0, 0, 0, 5.1%);
  opacity: 0;
  transition: all 200ms;
  transform: translateY(5px);

  &.open {
    visibility: visible;
    opacity: 1;
    transform: translateY(0);
  }

  hr {
    margin: 0;
  }
}

.block {
  display: grid;
  row-gap: 8px;
  padding: 32px 40px;
}

.title {
  color: palette(black);
}

.list {
  display: grid;
  color: palette(gray80);
}

.row {
  display: grid;
  grid-template-columns: 24px 1fr;
  column-gap: 12px;
  align-items: center;
  padding: 8px 0;

  &:is([href], [type='button']) {
    width: 100%;
    font-weight: 400;
    color: palette(green70);
    text-align: left;
    text-decoration: none;
    transition: color 250ms;

    svg {
      color: palette(green60);
      transition: color 250ms;
    }

    &:hover {
      color: palette(green100);

      svg {
        color: palette(green70);
      }
    }
  }
}
