/* stylelint-disable selector-pseudo-class-no-unknown */
@import '~styles/palette';

.wrap {
  position: relative;
  width: 100%;
  padding: 0 12px;

  .input {
    user-select: none;
    border-radius: 0;
    box-shadow: inset 0 -1px 0 palette(gray40);

    &::selection {
      background-color: transparent;
    }

    &:focus,
    &.focused {
      box-shadow: inset 0 -1px 0 palette(green60);
    }

    &.error {
      box-shadow: inset 0 -1px 0 palette(orange60);
    }

    &:global(.form-control) {
      background-position: right 6px bottom 8px;
    }
  }
}
