.icon {
  position: relative;
  z-index: 1;
  display: grid;
  place-items: center;
  border-radius: 50%;

  svg {
    z-index: 2;
  }
}

.iconWithBg {
  &::after {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    content: '';
    background: currentcolor;
    border-radius: inherit;
    opacity: 0.2;
  }
}
