@import '~styles/palette';
@import '~styles/scrollbar';

.list {
  @include scrollbar;

  max-height: calc(50vh - 60px);
  padding: 5px 5px 10px 14px;
  margin-right: -5px;
  margin-bottom: -10px;
  overflow-y: auto;
  list-style-type: disc;
  mask: linear-gradient(
    to bottom,
    transparent 0,
    #000 10px,
    #000 calc(100% - 15px),
    transparent 100%
  );
}
