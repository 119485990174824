@import '~styles/palette';

.toggle {
  display: grid;
  grid-template-columns: 24px 1fr;
  column-gap: 12px;
  align-items: center;
  padding: 16px 20px 16px 16px;
  color: palette(white);
  background-color: palette(green60);
  border-radius: 60px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 12%), 0 3px 12px rgba(0, 0, 0, 10%);
  transition: background-color 200ms;

  &:hover,
  &:focus {
    background-color: palette(green70);
    outline: none;
  }
}

.toggleText {
  font-size: 14px;
  line-height: 1.71;
  text-transform: uppercase;
  letter-spacing: 0.06em;
}
