@import '~styles/palette';

.row {
  position: relative;
  display: grid;
  grid-template-columns: 52px repeat(4, 56px);
  column-gap: 12px;
  align-items: center;
  height: 56px;

  &.withTooltip {
    grid-template-columns: 70px repeat(4, 75px);

    &:not(:last-of-type)::after {
      width: calc(100% - 82px);
    }
  }

  &:not(:last-of-type)::after {
    position: absolute;
    right: 0;
    bottom: 0;
    width: calc(100% - 68px);
    height: 1px;
    content: '';
    background-color: palette(gray20);
  }
}

.title {
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  color: palette(gray90);
  text-transform: uppercase;
  letter-spacing: 0.06em;
  cursor: default;
  user-select: none;
}
