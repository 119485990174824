@import '~styles/palette';

@mixin scrollbar {
  &::-webkit-scrollbar {
    width: 6px;
    background: transparent;

    &-thumb {
      height: 80px;
      background-color: rgba(palette(black), 0.2);
      border-radius: 6px;
      transition: all 500ms;

      &:hover {
        background-color: rgba(palette(black), 0.3);
      }
    }
  }

  // Mozilla Firefox Scrollbar
  scrollbar-width: thin;
  scrollbar-color: rgba(palette(black), 0.2) rgba(palette(black), 0.1);
}

@mixin scrollbarBig {
  @include scrollbar;

  padding-bottom: 5px;

  &::-webkit-scrollbar {
    height: 7px;
  }
}
