@import '~styles/palette';

.editBtnIcon {
  transition: all 500ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.editBtn {
  display: flex;
  align-items: center;
  height: 24px;
  padding: 0 8px;
  color: palette(gray50);
  background-color: palette(gray10);
  border-radius: 16px;
  transition: background-color 250ms;

  &:hover .editBtnIcon {
    transform: rotate(180deg);
  }
}

.editBtnText {
  margin: 0 4px 0 8px;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.71;
  color: palette(gray60);
  text-transform: uppercase;
  letter-spacing: 0.06em;
}
