@import '~styles/palette';

.calendar {
  position: absolute;
  top: calc(100% + 6px);
  left: 50%;
  z-index: 3;
  display: none;
  flex-direction: column;
  align-items: flex-start;
  min-height: 56px;
  max-height: 284px;
  padding: 0 20px;
  background: palette(white);
  border: 1px solid palette(gray20);
  border-radius: 8px;
  transform: translateX(-50%);

  &.open {
    display: flex;
  }

  &.month {
    right: 0;
    left: auto;
    padding: 16px 18px 22px;
    overflow-y: auto;
    transform: none;
  }
}
