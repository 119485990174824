.tooltip {
  line-height: 16px;
  letter-spacing: 0.01em;

  &-inner {
    box-shadow: 0 2px 8px 0 #00000040;
    backdrop-filter: blur(2px);

    a {
      color: inherit;
    }

    // Mozilla Firefox
    @supports not (backdrop-filter: blur(35px)) {
      background-color: rgba(palette(gray90), 0.8);
    }
  }

  &-arrow {
    overflow: hidden;
  }
}
