@import './palette';

/* stylelint-disable no-descending-specificity */
button {
  padding: 0;
  font-weight: 500;
  line-height: 1.5;
  color: inherit;
  cursor: pointer;
  background: none;
  border: 0;
  outline: none;
  appearance: none;
}

.btn {
  display: inline-grid;
  grid-auto-flow: column;
  column-gap: 8px;
  align-items: center;
  justify-content: space-between;
  min-width: 217px;
  min-height: 48px;

  &.loading {
    cursor: progress;
  }

  &-close {
    transition: opacity 200ms;
  }

  &-orange50 {
    box-shadow: 0 3px 8px rgba(palette(black), 12%);
  }

  &-fluid {
    width: 100%;
    min-width: auto;
  }

  &-gap {
    &-sm {
      column-gap: 6px;
    }

    &-md {
      column-gap: 16px;
    }

    &-lg {
      column-gap: 24px;
    }
  }

  &-outline-gray {
    --bs-btn-hover-bg: #{palette(gray20)};
    --bs-btn-active-bg: #{palette(gray20)};
    --bs-btn-hover-color: #{palette(gray70)};
    --bs-btn-active-color: #{palette(gray70)};
    --bs-btn-hover-border-color: #{palette(gray20)};
    --bs-btn-active-border-color: #{palette(gray20)};

    border-color: palette(gray30);

    &:hover,
    &:focus {
      color: palette(gray70);
      background-color: palette(gray20);
      border-color: palette(gray20);
    }

    svg {
      color: palette(green60);
    }

    &:disabled {
      border-color: palette(gray30);
    }
  }

  &-outline-green60 {
    border-color: palette(green60);

    &:hover,
    &:not(:disabled, .disabled):active {
      color: palette(white);
      background-color: palette(green60);
      border-color: palette(green60);

      svg {
        color: palette(white);
        transition: color 200ms;
      }
    }

    svg {
      color: palette(green60);
    }
  }

  &-link {
    min-width: auto;
    padding: 0;
  }

  &-center {
    justify-content: center;
  }

  &-toggle {
    position: relative;
    z-index: 2;
    display: flex;
    gap: 16px;
    align-items: center;
    justify-content: space-between;
    padding: 12px 15px;

    &:focus,
    &:focus:active {
      background: transparent;
      box-shadow: none;
    }

    &[aria-expanded='true'] {
      background-color: transparent;
      border-color: transparent;

      &:hover {
        color: palette(gray70);
        background-color: transparent;
        border-color: transparent;
      }

      svg {
        transform: scaleY(-1);
      }
    }

    svg {
      transition: all 200ms;
    }
  }
}
