// TODO: move to global keyframes
@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(-360deg);
  }
}

.toast {
  $this: &;

  display: grid;
  grid-template-columns: 1fr auto;
  grid-auto-flow: column;
  align-content: center;
  margin-top: var(--bs-toast-spacing);
  overflow: hidden;

  &.fade {
    transition-timing-function: ease-in-out;
    transition-duration: 0.25s;
    transition-property: opacity, transform, height, margin-top;
  }

  &.slideFromRight {
    transform: translateX(16px);
  }

  &.collapseVertically {
    height: 0 !important;
    margin-top: 0;
    overflow: hidden;
  }

  &-container {
    position: fixed;

    // Position: bottom-end
    &.bottom-end {
      right: 0;
      bottom: 0;
      display: flex;
      flex-direction: column-reverse;
      padding: 24px;
    }

    > #{$this}:not(:last-child) {
      margin-bottom: 0;
    }
  }

  &-header {
    align-items: start;
    order: 1;
    padding-left: 0;

    .btn-close {
      margin: -0.25em;
    }
  }

  &-body {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-auto-flow: column;
    column-gap: 12px;
    align-items: center;
    font-weight: 500;
  }

  // Animated spinner logo
  &-spinner {
    display: block;
    animation: rotate 1.2s linear 0s infinite;

    &-danger {
      filter: hue-rotate(220deg) brightness(100%) saturate(165%);
    }
  }
}
