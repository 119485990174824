.metric {
  display: flex;
  align-items: center;
  font-size: 28px;

  > div {
    margin-right: 16px;
  }

  span {
    color: #212529;
  }
}
