.spinner {
  &-border {
    border-color: palette(green60);
    border-right-color: transparent;
  }

  &-white {
    border-color: palette(white);
    border-right-color: transparent;
  }

  &-download {
    width: 22px;
    height: 22px;
    border-color: palette(gray60);
    border-right-color: transparent;
  }

  &-icon {
    background: transparent;
    border: none;
    animation-name: rotate;
  }

  &-badge--exports {
    width: 13px;
    height: 13px;
    border-color: palette(gray60);
    border-right-color: transparent;
  }
}

// TODO: move to global keyframes
@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(-360deg);
  }
}
