@import '~styles/palette';
@import '~styles/scrollbar';

%modal-btnClose--sm {
  .btn-close {
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24'%3E%3Cpath stroke='%2380868c' stroke-linejoin='round' stroke-width='2' d='M6 18 18 6M6 6l12 12'/%3E%3C/svg%3E");
  }
}

.modal {
  $this: &;

  overflow: auto;

  &-xs {
    @extend %modal-btnClose--sm;

    --bs-modal-width: 352px;

    #{$this}-content {
      padding: 14px 16px 24px;
    }
  }

  &-sm {
    @extend %modal-btnClose--sm;

    #{$this}-content {
      padding: 24px;
    }
  }

  &-md {
    #{$this}-content {
      padding: 34px 40px 40px;
    }
  }

  &-lg {
    min-width: 880px;

    #{$this}-content {
      padding: 16px;
    }
  }

  &-xl {
    #{$this}-content {
      min-height: 601px;
      padding: 32px 40px;
    }
  }

  &-save {
    #{$this}-content {
      padding: 34px 40px 40px;
    }

    h2 {
      color: palette(green60);
    }
  }

  &-disabledLink {
    #{$this}-body {
      margin-top: -15px;
    }
  }

  // Variant: delete prompt on tracker builder
  &-deletePrompt {
    #{$this}-body {
      display: grid;
      justify-items: center;
      margin-top: -14px;
      text-align: center;
    }

    h2 {
      margin-top: 16px;
      font-size: 18px;
      line-height: calc(22 / 18);
      color: palette(gray70);
    }

    p {
      margin-top: 8px;
      font-size: 14px;
      line-height: calc(24 / 14);
      color: palette(gray60);
      letter-spacing: 0.01em;
      word-break: break-word;
    }
  }

  .btn-close {
    z-index: 1;
    margin-left: auto;
  }
}
