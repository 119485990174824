@import '~styles/palette';

.scrollableList {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;

  &::before,
  &::after {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 10;
    width: 100%;
    height: 48px;
    pointer-events: none;
    visibility: hidden;
    content: '';
    background: linear-gradient(
      0deg,
      palette(white) 0%,
      rgba(palette(white), 0.99) 6.67%,
      rgba(palette(white), 0.96) 13.33%,
      rgba(palette(white), 0.91) 20%,
      rgba(palette(white), 0.85) 26.67%,
      rgba(palette(white), 0.76) 33.33%,
      rgba(palette(white), 0.66) 40%,
      rgba(palette(white), 0.55) 46.67%,
      rgba(palette(white), 0.44) 53.33%,
      rgba(palette(white), 0.33) 60%,
      rgba(palette(white), 0.23) 66.67%,
      rgba(palette(white), 0.14) 73.33%,
      rgba(palette(white), 0.08) 80%,
      rgba(palette(white), 0.03) 86.67%,
      rgba(palette(white), 0) 93.33%,
      rgba(palette(white), 0) 100%
    );
    opacity: 0;
    transition: opacity 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94),
      visibility 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
  }

  &::before {
    top: 0;
    transform: scale(1, -1);
  }

  &::after {
    bottom: 0;
  }

  &.visibleTop::before {
    visibility: visible;
    opacity: 1;
  }

  &.visibleBottom::after {
    visibility: visible;
    opacity: 1;
  }
}
