@import 'palette';
@import 'exports';

// Variables
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.

// Color system

$white: palette(white) !default;
$black: palette(black) !default;

$grays: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$grays: map-merge(
  (
    '100': palette(gray100),
    '200': palette(gray200),
    '300': palette(gray30),
    '400': palette(gray40),
    '500': palette(gray500),
    '600': palette(gray60),
    '700': palette(gray70),
    '800': palette(gray400),
    '900': palette(gray300),
  ),
  $grays
);

$primary: palette(green50);
$secondary: palette(orange50);
$success: palette(green60);
$info: palette(cyanb) !default;
$warning: palette(yellow) !default;
$danger: palette(redb) !default;
$light: palette(gray100) !default;
$dark: palette(gray400) !default;

$theme-colors: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$theme-colors: map-merge(
  (
    'primary': $primary,
    'secondary': $secondary,
    'success': $success,
    'info': $info,
    'warning': $warning,
    'danger': $danger,
    'light': $light,
    'dark': $dark,
    'gray': palette(gray70),
    'orange50': palette(orange50),
    'orange60': palette(orange60),
    'green60': palette(green60),
  ),
  $theme-colors
);

// scss-docs-start theme-colors-map
$theme-colors: (
  'primary': $primary,
  'secondary': $secondary,
  'success': $success,
  'info': $info,
  'warning': $warning,
  'danger': $danger,
  'light': $light,
  'dark': $dark,
) !default;
// scss-docs-end theme-colors-map

// scss-docs-start theme-colors-rgb
$theme-colors-rgb: map-loop($theme-colors, to-rgb, '$value') !default;
// scss-docs-end theme-colors-rgb

$badge-colors: (
  primary: (
    bg: palette(green10),
    color: palette(green70),
  ),
  secondary: (
    bg: palette(orange10),
    color: palette(orange60),
  ),
  gray: (
    bg: palette(gray10),
    color: palette(gray70),
  ),
  gray20: (
    bg: palette(gray20),
    color: palette(gray60),
  ),
);

// The contrast ratio to reach against white, to determine if color changes from "light" to "dark". Acceptable values for WCAG 2.0 are 3, 4.5 and 7.
// See https://www.w3.org/TR/WCAG20/#visual-audio-contrast-contrast
$min-contrast-ratio: 2.5;

// Customize the light and dark text colors for use in our color contrast function.
$color-contrast-dark: palette(gray300);
$color-contrast-light: $white !default;

// Characters which are escaped by the escape-svg function
$escaped-characters: (('<', '%3c'), ('>', '%3e'), ('#', '%23')) !default;

// Options
//
// Quickly modify global styling by enabling or disabling optional features.

$enable-caret: false;
$enable-rounded: true !default;
$enable-shadows: false !default;
$enable-gradients: false !default;
$enable-transitions: true !default;
$enable-reduced-motion: true !default;
$enable-smooth-scroll: false;
$enable-grid-classes: true !default;
$enable-cssgrid: false !default;
$enable-button-pointers: true !default;
$enable-rfs: false;
$enable-validation-icons: true !default;
$enable-negative-margins: false !default;
$enable-deprecation-messages: true !default;
$enable-important-utilities: true !default;

// Prefix for :root CSS variables

$variable-prefix: bs- !default; // Deprecated in v5.2.0 for the shorter `$prefix`
$prefix: $variable-prefix !default;

// Gradient
//
// The gradient which is added to components if `$enable-gradients` is `true`
// This gradient is also added to elements with `.bg-gradient`
// scss-docs-start variable-gradient
$gradient: linear-gradient(
  180deg,
  rgba($white, 0.15),
  rgba($white, 0)
) !default;
// scss-docs-end variable-gradient

// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

// scss-docs-start spacer-variables-maps
$spacer: 1rem !default;
$spacers: (
  0: 0,
  1: $spacer * 0.25,
  2: $spacer * 0.5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3,
) !default;

$negative-spacers: if(
  $enable-negative-margins,
  negativify-map($spacers),
  null
) !default;
// scss-docs-end spacer-variables-maps

// Position
//
// Define the edge positioning anchors of the position utilities.

// scss-docs-start position-map
$position-values: (
  0: 0,
  50: 50%,
  100: 100%,
) !default;
// scss-docs-end position-map

// Body
//
// Settings for the `<body>` element.

$body-bg: palette(bodybg);
$body-color: palette(gray70);
$body-text-align: null !default;

// Links
//
// Style anchor elements.

$link-color: palette(green70);
$link-decoration: none !default;
$link-hover-color: darken($link-color, 15%) !default;
$link-hover-decoration: underline !default;
// Darken percentage for links with `.text-*` class (e.g. `.text-success`)
$emphasized-link-hover-darken-percentage: 15% !default;

// Paragraphs
//
// Style p element.

$paragraph-margin-bottom: 0;

// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
) !default;

@include _assert-ascending($grid-breakpoints, '$grid-breakpoints');
@include _assert-starts-at-zero($grid-breakpoints, '$grid-breakpoints');

// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

// scss-docs-start container-max-widths
$container-max-widths: (
  // md: 720px,
  // lg: 960px,
  // xl: 1190px
  sm: 1200px
) !default;
// scss-docs-end container-max-widths

@include _assert-ascending($container-max-widths, '$container-max-widths');

// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-columns: 12 !default;
$grid-gutter-width: 40px !default;
$grid-row-columns: 6 !default;

$gutters: $spacers !default;

// Container padding

$container-padding-x: $grid-gutter-width * 0.5 !default;

// Components
//
// Define common padding and border radius sizes and more.

// scss-docs-start border-variables
$border-width: 1px !default;
$border-widths: (
  1: 1px,
  2: 2px,
  3: 3px,
  4: 4px,
  5: 5px,
) !default;

$line-height-lg: 1.33;
$line-height-sm: 1.5 !default;

$border-width: 0;
$border-style: solid !default;
$border-color: palette(gray30) !default;
$border-color-translucent: rgba($black, 0.175) !default;

$border-radius: 0.25rem !default;
$border-radius-sm: 0.2rem !default;
$border-radius-lg: 0.3rem !default;
$border-radius-xl: 1rem !default;
$border-radius-2xl: 2rem !default;
$border-radius-pill: 50rem !default;

$rounded-pill: 50rem !default;

// scss-docs-start box-shadow-variables
$box-shadow-sm: 0 0.125rem 0.25rem rgba($black, 0.075) !default;
$box-shadow: 0 0.5rem 1rem rgba($black, 0.15) !default;
$box-shadow-lg: 0 1rem 3rem rgba($black, 0.175) !default;
$box-shadow-inset: inset 0 1px 2px rgba($black, 0.075) !default;
// scss-docs-end box-shadow-variables

$component-active-color: $white !default;
$component-active-bg: $primary !default;

$caret-width: 0.3em !default;
$caret-vertical-align: $caret-width * 0.85 !default;
$caret-spacing: $caret-width * 0.85 !default;

$transition-base: all 0.2s ease-in-out !default;
$transition-fade: opacity $transition-fade-duration linear;
// scss-docs-start collapse-transition
$transition-collapse: height 0.35s ease !default;
$transition-collapse-width: width 0.35s ease !default;
// scss-docs-end collapse-transition

// stylelint-disable function-disallowed-list
// scss-docs-start aspect-ratios
$aspect-ratios: (
  '1x1': 100%,
  '4x3': calc(3 / 4 * 100%),
  '16x9': calc(9 / 16 * 100%),
  '21x9': calc(9 / 21 * 100%),
) !default;
// scss-docs-end aspect-ratios
// stylelint-enable function-disallowed-list

// Typography
//
// Font, line-height, and color for body text, headings, and more.

// stylelint-disable value-keyword-case
$font-family-sans-serif: 'Red Hat Display', -apple-system, 'Segoe UI', Roboto,
  'Helvetica Neue', Arial, 'Noto Sans', 'Liberation Sans', sans-serif,
  'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
$font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
  'Liberation Mono', 'Courier New', monospace !default;
$font-family-base: var(--#{$variable-prefix}font-sans-serif) !default;
$font-family-code: var(--#{$variable-prefix}font-monospace) !default;
// stylelint-enable value-keyword-case

$font-size-root: null !default;
$font-size-base: 18px; // Assumes the browser default, typically `16px`
$font-size-lg: $font-size-base * 1.25 !default;
$font-size-sm: $font-size-base * 0.875 !default;

$font-weight-lighter: lighter !default;
$font-weight-light: 300 !default;
$font-weight-normal: 400 !default;
$font-weight-bold: 700 !default;
$font-weight-bolder: bolder !default;

$font-weight-base: $font-weight-normal !default;
$line-height-base: 1.33;

$h1-font-size: $font-size-base * 1.555;
$h2-font-size: $font-size-base * 1.555556;
$h3-font-size: $font-size-base * 1.2222;
$h4-font-size: $font-size-base;
$h5-font-size: $font-size-base * 1.25 !default;
$h6-font-size: $font-size-base !default;
// scss-docs-end font-variables

// scss-docs-start font-sizes
$font-sizes: (
  1: $h1-font-size,
  2: $h2-font-size,
  3: $h3-font-size,
  4: $h4-font-size,
  5: $h5-font-size,
  6: $h6-font-size,
) !default;
// scss-docs-end font-sizes

// scss-docs-start headings-variables
$headings-margin-bottom: 0;
$headings-font-family: null !default;
$headings-font-style: null !default;
$headings-font-weight: 500;
$headings-line-height: $line-height-base;
$headings-color: palette(gray80);
// scss-docs-end headings-variables

// scss-docs-start display-headings
$display-font-sizes: (
  1: 6rem,
  2: 5.5rem,
  3: 4.5rem,
  4: 3.5rem,
  5: 3rem,
  6: 2.5rem,
) !default;

$display-font-family: null !default;
$display-font-style: null !default;
$display-font-weight: 300 !default;
$display-line-height: $headings-line-height !default;
// scss-docs-end display-headings

$lead-font-size: $font-size-base;
$lead-font-weight: 400;

$small-font-size: 0.64em;

$sub-sup-font-size: 0.75em !default;

$text-muted: palette(gray60);

$initialism-font-size: $small-font-size !default;

$blockquote-margin-y: $spacer !default;
$blockquote-font-size: $font-size-base * 1.25 !default;
$blockquote-footer-color: palette(gray60) !default;
$blockquote-footer-font-size: $small-font-size !default;

$hr-margin-y: $spacer !default;
$hr-color: palette(gray30);

// fusv-disable
$hr-bg-color: null !default; // Deprecated in v5.2.0
$hr-height: null !default; // Deprecated in v5.2.0
// fusv-enable

$hr-border-color: null !default; // Allows for inherited colors
$hr-border-width: 1px;
$hr-opacity: 1;

$legend-margin-bottom: 0.5rem !default;
$legend-font-size: 1.5rem !default;
$legend-font-weight: null !default;

$mark-padding: 0 2px;

$dt-font-weight: $font-weight-bold !default;

$kbd-box-shadow: inset 0 -0.1rem 0 rgba($black, 0.25) !default;
$nested-kbd-font-weight: $font-weight-bold !default;

$list-inline-padding: 0.5rem !default;

$mark-bg: #fceb91;

// Tables
//
// Customizes the `.table` component with basic values, each used across all table variations.

// scss-docs-start table-variables
$table-cell-padding-y: 0.75rem !default;
$table-cell-padding-x: 0.75rem !default;
$table-cell-padding-y-sm: 0.3rem !default;
$table-cell-padding-x-sm: 0.3rem !default;

$table-cell-vertical-align: top !default;

$table-color: $body-color !default;
$table-bg: null !default;
$table-accent-bg: rgba($black, 0.05) !default;

$table-th-font-weight: null !default;

$table-hover-color: $table-color !default;
$table-hover-bg: rgba($black, 0.075) !default;
$table-active-bg: $table-hover-bg !default;

$table-border-width: $border-width !default;
$table-border-color: $border-color !default;

$table-head-bg: transparent;
$table-head-color: palette(gray70) !default;

$table-dark-color: $white !default;
$table-dark-bg: palette(gray400) !default;
$table-dark-accent-bg: rgba($white, 0.05) !default;
$table-dark-hover-color: $table-dark-color !default;
$table-dark-hover-bg: rgba($white, 0.075) !default;
$table-dark-border-color: lighten($table-dark-bg, 7.5%) !default;

$table-striped-order: odd !default;

$table-caption-color: $text-muted !default;

$table-bg-level: -9 !default;
$table-border-level: -6 !default;

// Buttons + Forms
//
// Shared variables that are reassigned to `$input-` and `$btn-` specific variables.

$input-btn-padding-y: 0.5rem;
$input-btn-padding-x: 0.75rem;
$input-btn-font-family: null !default;
$input-btn-font-size: $font-size-base !default;
$input-btn-line-height: $line-height-base !default;

$input-btn-focus-width: 0.2rem !default;
$input-btn-focus-color-opacity: 0.25 !default;
$input-btn-focus-color: rgba($component-active-bg, 0.25) !default;
$input-btn-focus-blur: 0 !default;
$input-btn-focus-box-shadow: 0 0 0 $input-btn-focus-width $input-btn-focus-color !default;

$input-btn-padding-y-sm: 0.25rem !default;
$input-btn-padding-x-sm: 0.5rem !default;
$input-btn-font-size-sm: $font-size-sm !default;
$input-btn-line-height-sm: $line-height-sm !default;

$input-btn-padding-y-lg: 0.5rem !default;
$input-btn-padding-x-lg: 1rem !default;
$input-btn-font-size-lg: $font-size-lg !default;
$input-btn-line-height-lg: $line-height-lg !default;

$input-btn-border-width: 0;

// Buttons
//
// For each of Bootstrap's buttons, define text, background, and border color.

$btn-padding-y: 11px;
$btn-padding-x: 16px;
$btn-font-family: $input-btn-font-family !default;
$btn-font-size: $input-btn-font-size !default;
$btn-line-height: 1.22;
$btn-white-space: null !default; // Set to `nowrap` to prevent text wrapping

$btn-padding-y-sm: $input-btn-padding-y-sm !default;
$btn-padding-x-sm: $input-btn-padding-x-sm !default;
$btn-font-size-sm: $input-btn-font-size-sm !default;
$btn-line-height-sm: $input-btn-line-height-sm !default;

$btn-padding-y-lg: $input-btn-padding-y-lg !default;
$btn-padding-x-lg: $input-btn-padding-x-lg !default;
$btn-font-size-lg: $input-btn-font-size-lg !default;
$btn-line-height-lg: $input-btn-line-height-lg !default;

$btn-border-width: 1px;

$btn-font-weight: 500;
$btn-box-shadow: inset 0 1px 0 rgba($white, 0.15), 0 1px 1px rgba($black, 0.075) !default;
$btn-focus-width: $input-btn-focus-width !default;
$btn-focus-box-shadow: $input-btn-focus-box-shadow !default;
$btn-disabled-opacity: 0.65 !default;
$btn-active-box-shadow: inset 0 3px 5px rgba($black, 0.125) !default;

$btn-link-color: $link-color !default;
$btn-link-hover-color: $link-hover-color !default;
$btn-link-disabled-color: palette(gray60) !default;

$btn-block-spacing-y: 0.5rem !default;

// Allows for customizing button radius independently from global border radius
$btn-border-radius: 8px;
$btn-border-radius-lg: $border-radius-lg !default;
$btn-border-radius-sm: $border-radius-sm !default;

$btn-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
  border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !default;

$btn-hover-bg-shade-amount: 15% !default;
$btn-hover-bg-tint-amount: 15% !default;
$btn-hover-border-shade-amount: 20% !default;
$btn-hover-border-tint-amount: 10% !default;
$btn-active-bg-shade-amount: 20% !default;
$btn-active-bg-tint-amount: 20% !default;
$btn-active-border-shade-amount: 25% !default;
$btn-active-border-tint-amount: 10% !default;

// Forms

// scss-docs-start form-text-variables
$form-text-margin-top: 0.25rem !default;
$form-text-font-size: $small-font-size !default;
$form-text-font-style: null !default;
$form-text-font-weight: null !default;
$form-text-color: $text-muted !default;
// scss-docs-end form-text-variables

// scss-docs-start form-label-variables
$form-label-margin-bottom: 0.5rem !default;
$form-label-font-size: null !default;
$form-label-font-style: null !default;
$form-label-font-weight: null !default;
$form-label-color: null !default;
// scss-docs-end form-label-variables

$label-margin-bottom: 0;

$input-padding-y: $input-btn-padding-y !default;
$input-padding-x: 0;
$input-font-family: $input-btn-font-family !default;
$input-font-size: $input-btn-font-size !default;
$input-font-weight: $font-weight-base !default;
$input-line-height: $input-btn-line-height !default;

$input-padding-y-sm: $input-btn-padding-y-sm !default;
$input-padding-x-sm: $input-btn-padding-x-sm !default;
$input-font-size-sm: $input-btn-font-size-sm !default;
$input-line-height-sm: $input-btn-line-height-sm !default;

$input-padding-y-lg: $input-btn-padding-y-lg !default;
$input-padding-x-lg: $input-btn-padding-x-lg !default;
$input-font-size-lg: $input-btn-font-size-lg !default;
$input-line-height-lg: $input-btn-line-height-lg !default;

$input-bg: $white !default;
$input-disabled-color: null !default;
$input-disabled-bg: transparent;
$input-disabled-border-color: null !default;

$input-color: palette(gray100);
$input-border-color: palette(gray40) !default;
$input-border-width: $input-btn-border-width !default;
$input-box-shadow: inset 0 2px 1px $black;

$input-border-radius: 4px;
$input-border-radius-lg: $border-radius-lg !default;
$input-border-radius-sm: $border-radius-sm !default;

$input-focus-bg: $input-bg !default;
$input-focus-border-color: null;
$input-focus-color: $input-color !default;
$input-focus-width: 0;
$input-focus-box-shadow: inset 0 -2px 0 palette(green60);

$input-placeholder-color: palette(gray70);
$input-plaintext-color: $body-color !default;

$input-height-border: $input-border-width * 2 !default;

$input-height-inner: add(
  $input-line-height * 1em,
  $input-padding-y * 2
) !default;
$input-height-inner-half: add(
  $input-line-height * 0.5em,
  $input-padding-y
) !default;
$input-height-inner-quarter: add(
  $input-line-height * 0.25em,
  $input-padding-y * 0.5
) !default;

$input-height: add(
  $input-line-height * 1em,
  add($input-padding-y * 2, $input-height-border, false)
) !default;
$input-height-sm: add(
  $input-line-height-sm * 1em,
  add($input-padding-y-sm * 2, $input-height-border, false)
) !default;
$input-height-lg: add(
  $input-line-height-lg * 1em,
  add($input-padding-y-lg * 2, $input-height-border, false)
) !default;

$input-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !default;

$form-text-margin-top: 0.25rem !default;

// scss-docs-start form-check-variables
$form-check-input-width: 18px;
$form-check-min-height: 18px;
$form-check-padding-start: 0;
$form-check-margin-bottom: 0;
$form-check-label-color: palette(gray80);
$form-check-label-cursor: null !default;
$form-check-transition: 250ms;

$form-check-input-active-filter: brightness(90%) !default;

$form-check-input-bg: transparent;
$form-check-input-border: 2px solid palette(gray60);
$form-check-input-border-radius: 0.15em;
$form-check-radio-border-radius: 50% !default;
$form-check-input-focus-border: $input-focus-border-color !default;
$form-check-input-focus-box-shadow: none;
$form-check-input-focus-box-shadow: none;

$form-check-input-checked-color: $component-active-color !default;
$form-check-input-checked-bg-color: palette(green60);
$form-check-input-checked-border-color: $form-check-input-checked-bg-color !default;
$form-check-input-checked-bg-image: url("data:image/svg+xml,%3Csvg width='13' height='10' viewBox='0 0 13 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 4L5 8L12 1' stroke='#{$form-check-input-checked-color}' stroke-width='2'/%3E%3C/svg%3E%0A");
$form-check-radio-checked-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='2' fill='#{$form-check-input-checked-color}'/></svg>") !default;

$form-check-input-indeterminate-color: $component-active-color !default;
$form-check-input-indeterminate-bg-color: $component-active-bg !default;
$form-check-input-indeterminate-border-color: $form-check-input-indeterminate-bg-color !default;
$form-check-input-indeterminate-bg-image: url("data:image/svg+xml,%3Csvg width='14' height='2' viewBox='0 0 10 2' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 1H10' stroke='#{$form-check-input-checked-color}' stroke-width='2' stroke-linejoin='round'/%3E%3C/svg%3E%0A");

$form-check-input-disabled-opacity: 0.5 !default;
$form-check-label-disabled-opacity: $form-check-input-disabled-opacity !default;
$form-check-btn-check-disabled-opacity: $btn-disabled-opacity !default;

$form-check-inline-margin-end: 1rem !default;
// scss-docs-end form-check-variables

$form-grid-gutter-width: 10px !default;
$form-group-margin-bottom: 1rem !default;

$input-group-addon-color: $input-color !default;
$input-group-addon-bg: palette(gray200) !default;
$input-group-addon-border-color: $input-border-color !default;

$custom-forms-transition: background-color 0.15s ease-in-out,
  border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !default;

$custom-control-gutter: 0.5rem !default;
$custom-control-spacer-x: 1rem !default;
$custom-control-cursor: null !default;

$custom-control-indicator-size: 1.375rem;
$custom-control-indicator-bg: $input-bg !default;

$custom-control-indicator-bg-size: 50% 50% !default;
$custom-control-indicator-box-shadow: $input-box-shadow !default;
$custom-control-indicator-border-color: palette(gray500) !default;
$custom-control-indicator-border-width: $input-border-width !default;

$custom-control-label-color: null !default;

$custom-control-indicator-disabled-bg: $input-disabled-bg !default;
$custom-control-label-disabled-color: palette(gray60) !default;

$custom-control-indicator-checked-color: $component-active-color !default;
$custom-control-indicator-checked-bg: $component-active-bg !default;
$custom-control-indicator-checked-disabled-bg: rgba($primary, 0.5) !default;
$custom-control-indicator-checked-box-shadow: none !default;
$custom-control-indicator-checked-border-color: $custom-control-indicator-checked-bg !default;

$custom-control-indicator-focus-box-shadow: $input-focus-box-shadow !default;
$custom-control-indicator-focus-border-color: $input-focus-border-color !default;

$custom-control-indicator-active-color: $component-active-color !default;
$custom-control-indicator-active-bg: lighten(
  $component-active-bg,
  35%
) !default;
$custom-control-indicator-active-box-shadow: none !default;
$custom-control-indicator-active-border-color: $custom-control-indicator-active-bg !default;

$custom-checkbox-indicator-border-radius: $border-radius !default;
$custom-checkbox-indicator-icon-checked: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'><path fill='#{$custom-control-indicator-checked-color}' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/></svg>") !default;

$custom-checkbox-indicator-indeterminate-bg: $component-active-bg !default;
$custom-checkbox-indicator-indeterminate-color: $custom-control-indicator-checked-color !default;
$custom-checkbox-indicator-icon-indeterminate: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'><path stroke='#{$custom-checkbox-indicator-indeterminate-color}' d='M0 2h4'/></svg>") !default;
$custom-checkbox-indicator-indeterminate-box-shadow: none !default;
$custom-checkbox-indicator-indeterminate-border-color: $custom-checkbox-indicator-indeterminate-bg !default;

$custom-radio-indicator-border-radius: 50% !default;
$custom-radio-indicator-icon-checked: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'><circle r='3' fill='#{$custom-control-indicator-checked-color}'/></svg>") !default;

// scss-docs-start form-switch-variables
$form-switch-color: rgba($black, 0.25) !default;
$form-switch-width: 40px;
$form-switch-padding-start: 0;
$form-switch-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{$form-switch-color}'/></svg>") !default;
$form-switch-border-radius: 0.6875rem;
$form-switch-transition: background-position 0.15s ease-in-out !default;

$form-switch-focus-color: $input-focus-border-color !default;
$form-switch-focus-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{$form-switch-color}'/></svg>") !default;

$form-switch-checked-color: $component-active-color !default;
$form-switch-checked-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{$form-switch-checked-color}'/></svg>") !default;
$form-switch-checked-bg-position: right center !default;
// scss-docs-end form-switch-variables

// scss-docs-start form-select-variables
$form-select-padding-y: $input-padding-y !default;
$form-select-padding-x: $input-padding-x !default;
$form-select-font-family: $input-font-family !default;
$form-select-font-size: $input-font-size !default;
$form-select-indicator-padding: $form-select-padding-x * 3 !default; // Extra padding for background-image
$form-select-font-weight: $input-font-weight !default;
$form-select-line-height: $input-line-height !default;
$form-select-color: $input-color !default;
$form-select-bg: $input-bg !default;
$form-select-disabled-color: null !default;
$form-select-disabled-bg: palette(gray200) !default;
$form-select-disabled-border-color: $input-disabled-border-color !default;
$form-select-bg-position: right $form-select-padding-x center !default;
$form-select-bg-size: 16px 12px !default; // In pixels because image dimensions
$form-select-indicator-color: palette(gray400) !default;
$form-select-indicator: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><path fill='none' stroke='#{$form-select-indicator-color}' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/></svg>") !default;

$form-select-feedback-icon-padding-end: $form-select-padding-x * 2.5 +
  $form-select-indicator-padding !default;
$form-select-feedback-icon-position: center right $form-select-indicator-padding !default;
$form-select-feedback-icon-size: $input-height-inner-half
  $input-height-inner-half !default;

$form-select-border-width: $input-border-width !default;
$form-select-border-color: $input-border-color !default;
$form-select-border-radius: $border-radius !default;
$form-select-border-radius-sm: $input-border-radius-sm !default;
$form-select-box-shadow: $box-shadow-inset !default;

$form-select-focus-border-color: $input-focus-border-color !default;
$form-select-focus-width: $input-focus-width !default;
$form-select-focus-box-shadow: 0 0 0 $form-select-focus-width
  $input-btn-focus-color !default;

$form-select-padding-y-sm: $input-padding-y-sm !default;
$form-select-padding-x-sm: $input-padding-x-sm !default;
$form-select-font-size-sm: $input-font-size-sm !default;

$form-select-padding-y-lg: $input-padding-y-lg !default;
$form-select-padding-x-lg: $input-padding-x-lg !default;
$form-select-font-size-lg: $input-font-size-lg !default;
$form-select-border-radius-lg: $input-border-radius-lg !default;

$form-select-transition: $input-transition !default;
// scss-docs-end form-select-variables

$custom-range-track-width: 100% !default;
$custom-range-track-height: 0.5rem !default;
$custom-range-track-cursor: pointer !default;
$custom-range-track-bg: palette(gray30) !default;
$custom-range-track-border-radius: 1rem !default;
$custom-range-track-box-shadow: inset 0 0.25rem 0.25rem rgba($black, 0.1) !default;

$custom-range-thumb-width: 1rem !default;
$custom-range-thumb-height: $custom-range-thumb-width !default;
$custom-range-thumb-bg: $component-active-bg !default;
$custom-range-thumb-border: 0 !default;
$custom-range-thumb-border-radius: 1rem !default;
$custom-range-thumb-box-shadow: 0 0.1rem 0.25rem rgba($black, 0.1) !default;
$custom-range-thumb-focus-box-shadow: 0 0 0 1px $body-bg,
  $input-focus-box-shadow !default;
$custom-range-thumb-focus-box-shadow-width: $input-focus-width !default; // For focus box shadow issue in IE/Edge
$custom-range-thumb-active-bg: lighten($component-active-bg, 35%) !default;
$custom-range-thumb-disabled-bg: palette(gray500) !default;

$custom-file-height: $input-height !default;
$custom-file-height-inner: $input-height-inner !default;
$custom-file-focus-border-color: $input-focus-border-color !default;
$custom-file-focus-box-shadow: $input-focus-box-shadow !default;
$custom-file-disabled-bg: $input-disabled-bg !default;

$custom-file-padding-y: $input-padding-y !default;
$custom-file-padding-x: $input-padding-x !default;
$custom-file-line-height: $input-line-height !default;
$custom-file-font-family: $input-font-family !default;
$custom-file-font-weight: $input-font-weight !default;
$custom-file-color: $input-color !default;
$custom-file-bg: $input-bg !default;
$custom-file-border-width: $input-border-width !default;
$custom-file-border-color: $input-border-color !default;
$custom-file-border-radius: $input-border-radius !default;
$custom-file-box-shadow: $input-box-shadow !default;
$custom-file-button-color: $custom-file-color !default;
$custom-file-button-bg: $input-group-addon-bg !default;
$custom-file-text: (
  en: 'Browse',
) !default;

// scss-docs-start form-file-variables
$form-file-button-color: $input-color !default;
$form-file-button-bg: $input-group-addon-bg !default;
$form-file-button-hover-bg: shade-color($form-file-button-bg, 5%) !default;
// scss-docs-end form-file-variables

$form-color-width: null;

// Form validation

$form-feedback-margin-top: $form-text-margin-top !default;
$form-feedback-font-size: $small-font-size;
$form-feedback-font-style: $form-text-font-style !default;
$form-feedback-valid-color: $success !default;
$form-feedback-invalid-color: $danger !default;

$form-feedback-icon-valid-color: $form-feedback-valid-color !default;
$form-feedback-icon-valid: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'><path fill='#{$form-feedback-icon-valid-color}' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/></svg>") !default;
$form-feedback-icon-invalid-color: $form-feedback-invalid-color !default;
$form-feedback-icon-invalid: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='#{$form-feedback-icon-invalid-color}' viewBox='0 0 12 12'><circle cx='6' cy='6' r='4.5'/><path stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/><circle cx='6' cy='8.2' r='.6' fill='#{$form-feedback-icon-invalid-color}' stroke='none'/></svg>") !default;

$form-validation-states: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$form-validation-states: map-merge(
  (
    'valid': (
      'color': $form-feedback-valid-color,
      'icon': $form-feedback-icon-valid,
    ),
    'invalid': (
      'color': $form-feedback-invalid-color,
      'icon': $form-feedback-icon-invalid,
    ),
  ),
  $form-validation-states
);

// Z-index master list
//
// Warning: Avoid customizing these values. They're used for a bird's eye view
// of components dependent on the z-axis and are designed to all work together.

$zindex-dropdown: 6;
$zindex-sticky: 1020 !default;
$zindex-fixed: 1030 !default;
$zindex-offcanvas-backdrop: 1040 !default;
$zindex-offcanvas: 1045 !default;
$zindex-modal-backdrop: 1050 !default;
$zindex-modal: 1055 !default;
$zindex-popover: 1060 !default;
$zindex-tooltip: 1070 !default;
$zindex-toast: 1090 !default;

// Navs

$nav-link-padding-y: 4px;
$nav-link-padding-x: 0;
$nav-link-font-size: null !default;
$nav-link-font-weight: null !default;
$nav-link-color: $link-color !default;
$nav-link-hover-color: $link-hover-color !default;
$nav-link-transition: color 0.15s ease-in-out,
  background-color 0.15s ease-in-out, border-color 0.15s ease-in-out !default;
$nav-link-disabled-color: palette(gray60) !default;

$nav-tabs-border-color: palette(gray30) !default;
$nav-tabs-border-width: $border-width !default;
$nav-tabs-border-radius: $border-radius !default;
$nav-tabs-link-hover-border-color: palette(gray200) palette(gray200)
  $nav-tabs-border-color !default;
$nav-tabs-link-active-color: palette(gray70) !default;
$nav-tabs-link-active-bg: $body-bg !default;
$nav-tabs-link-active-border-color: palette(gray30) palette(gray30)
  $nav-tabs-link-active-bg !default;

$nav-pills-border-radius: $border-radius !default;
$nav-pills-link-active-color: $component-active-color !default;
$nav-pills-link-active-bg: $component-active-bg !default;

$nav-divider-color: palette(gray200) !default;
$nav-divider-margin-y: $spacer * 0.5 !default;

// Navbar

$navbar-padding-y: $spacer * 0.5 !default;
$navbar-padding-x: $spacer !default;

$navbar-nav-link-padding-x: 0.5rem !default;

$navbar-brand-font-size: $font-size-lg !default;
// Compute the navbar-brand padding-y so the navbar-brand will have the same height as navbar-text and nav-link
$nav-link-height: $font-size-base * $line-height-base + $nav-link-padding-y * 2 !default;
$navbar-brand-height: $navbar-brand-font-size * $line-height-base !default;
$navbar-brand-padding-y: ($nav-link-height - $navbar-brand-height) * 0.5 !default;

$navbar-toggler-padding-y: 0.25rem !default;
$navbar-toggler-padding-x: 0.75rem !default;
$navbar-toggler-font-size: $font-size-lg !default;
$navbar-toggler-border-radius: $btn-border-radius !default;

$navbar-dark-color: rgba($white, 0.5) !default;
$navbar-dark-hover-color: rgba($white, 0.75) !default;
$navbar-dark-active-color: $white !default;
$navbar-dark-disabled-color: rgba($white, 0.25) !default;
$navbar-dark-toggler-icon-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'><path stroke='#{$navbar-dark-color}' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>") !default;
$navbar-dark-toggler-border-color: rgba($white, 0.1) !default;

$navbar-light-color: rgba($black, 0.5) !default;
$navbar-light-hover-color: rgba($black, 0.7) !default;
$navbar-light-active-color: rgba($black, 0.9) !default;
$navbar-light-disabled-color: rgba($black, 0.3) !default;
$navbar-light-toggler-icon-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'><path stroke='#{$navbar-light-color}' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>") !default;
$navbar-light-toggler-border-color: rgba($black, 0.1) !default;

$navbar-light-brand-color: $navbar-light-active-color !default;
$navbar-light-brand-hover-color: $navbar-light-active-color !default;
$navbar-dark-brand-color: $navbar-dark-active-color !default;
$navbar-dark-brand-hover-color: $navbar-dark-active-color !default;

// Dropdowns
//
// Dropdown menu container and contents.

$dropdown-min-width: 100%;
$dropdown-padding-x: 0 !default;
$dropdown-padding-y: 0;
$dropdown-spacer: 0.125rem !default;
$dropdown-font-size: $font-size-base !default;
$dropdown-color: $body-color !default;
$dropdown-bg: $white !default;
$dropdown-border-color: transparent;
$dropdown-border-radius: 8px;
$dropdown-border-width: 0;
$dropdown-inner-border-radius: subtract(
  $dropdown-border-radius,
  $dropdown-border-width
) !default;
$dropdown-divider-bg: palette(gray30);
$dropdown-divider-margin-y: 0;
$dropdown-box-shadow: 0px 6px 24px rgba($black, 0.2);

$dropdown-link-color: palette(gray70);
$dropdown-link-hover-color: palette(gray80);
$dropdown-link-hover-bg: null;

$dropdown-link-active-color: null;
$dropdown-link-active-bg: null;

$dropdown-link-disabled-color: palette(gray60) !default;

$dropdown-item-padding-y: 13px;
$dropdown-item-padding-x: 16px;

$dropdown-header-color: palette(gray60) !default;
$dropdown-header-padding-x: $dropdown-item-padding-x !default;
$dropdown-header-padding-y: $dropdown-padding-y !default;
// fusv-disable
$dropdown-header-padding: $dropdown-header-padding-y $dropdown-header-padding-x !default; // Deprecated in v5.2.0
// fusv-enable
// scss-docs-end dropdown-variables

// scss-docs-start dropdown-dark-variables
$dropdown-dark-color: palette(gray30) !default;
$dropdown-dark-bg: palette(gray400) !default;
$dropdown-dark-border-color: $dropdown-border-color !default;
$dropdown-dark-divider-bg: $dropdown-divider-bg !default;
$dropdown-dark-box-shadow: null !default;
$dropdown-dark-link-color: $dropdown-dark-color !default;
$dropdown-dark-link-hover-color: $white !default;
$dropdown-dark-link-hover-bg: rgba($white, 0.15) !default;
$dropdown-dark-link-active-color: $dropdown-link-active-color !default;
$dropdown-dark-link-active-bg: $dropdown-link-active-bg !default;
$dropdown-dark-link-disabled-color: palette(gray500) !default;
$dropdown-dark-header-color: palette(gray500) !default;
// scss-docs-end dropdown-dark-variables

// Pagination

$pagination-padding-y: 0.25rem;
$pagination-padding-x: 0.25rem;
$pagination-padding-y-sm: 0.25rem !default;
$pagination-padding-x-sm: 0.5rem !default;
$pagination-padding-y-lg: 0.75rem !default;
$pagination-padding-x-lg: 1.5rem !default;
$pagination-line-height: 1.25 !default;

$pagination-font-size: $font-size-base !default;

$pagination-color: #686e73;
$pagination-bg: null;
$pagination-border-width: 1px;
$pagination-border-radius: 4px;
$pagination-margin-start: -$pagination-border-width !default;
$pagination-border-color: transparent;

$pagination-focus-color: null;
$pagination-focus-bg: null;
$pagination-focus-box-shadow: null;
$pagination-focus-outline: 0 !default;

$pagination-hover-color: #14181a;
$pagination-hover-bg: null;
$pagination-hover-border-color: #cbd1d6;

$pagination-active-color: $component-active-color !default;
$pagination-active-bg: $component-active-bg !default;
$pagination-active-border-color: $pagination-active-bg !default;

$pagination-disabled-color: palette(gray60) !default;
$pagination-disabled-bg: $white !default;
$pagination-disabled-border-color: transparent;

$pagination-transition: color 0.15s ease-in-out,
  background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
  box-shadow 0.15s ease-in-out !default;

$pagination-border-radius-sm: $border-radius-sm !default;
$pagination-border-radius-lg: $border-radius-lg !default;

// Jumbotron

$jumbotron-padding: 2rem !default;
$jumbotron-color: null !default;
$jumbotron-bg: palette(gray200) !default;

// Placeholders

// scss-docs-start placeholders
$placeholder-opacity-max: 0.5 !default;
$placeholder-opacity-min: 0.2 !default;
// scss-docs-end placeholders

// Cards

$card-spacer-y: 0.75rem !default;
$card-spacer-x: 1.25rem !default;
$card-border-width: $border-width !default;
$card-border-radius: $border-radius !default;
$card-border-color: rgba($black, 0.125) !default;
$card-inner-border-radius: subtract(
  $card-border-radius,
  $card-border-width
) !default;
$card-cap-bg: rgba($black, 0.03) !default;
$card-cap-color: null !default;
$card-height: null !default;
$card-color: null !default;
$card-bg: $white !default;

$card-img-overlay-padding: 1.25rem !default;

$card-group-margin: $grid-gutter-width * 0.5 !default;
$card-deck-margin: $card-group-margin !default;

$card-columns-count: 3 !default;
$card-columns-gap: 1.25rem !default;
$card-columns-margin: $card-spacer-y !default;

// Tooltips

$tooltip-font-size: 14px;
$tooltip-max-width: null;
$tooltip-color: $white !default;
$tooltip-bg: rgba($black, 0.6);
$tooltip-border-radius: 4px;
$tooltip-opacity: 1;
$tooltip-padding-y: 12px;
$tooltip-padding-x: 16px;
$tooltip-margin: 4px;

$tooltip-arrow-width: 0.8rem !default;
$tooltip-arrow-height: 0.4rem !default;
$tooltip-arrow-color: $tooltip-bg !default;

// Form tooltips must come after regular tooltips
$form-feedback-tooltip-padding-y: $tooltip-padding-y !default;
$form-feedback-tooltip-padding-x: $tooltip-padding-x !default;
$form-feedback-tooltip-font-size: $tooltip-font-size !default;
$form-feedback-tooltip-line-height: $line-height-base !default;
$form-feedback-tooltip-opacity: $tooltip-opacity !default;
$form-feedback-tooltip-border-radius: $tooltip-border-radius !default;

// Popovers

$popover-font-size: $font-size-sm !default;
$popover-bg: $white !default;
$popover-max-width: 276px !default;
$popover-border-width: $border-width !default;
$popover-border-color: rgba($black, 0.2) !default;
$popover-border-radius: $border-radius-lg !default;
$popover-inner-border-radius: subtract(
  $popover-border-radius,
  $popover-border-width
) !default;
$popover-box-shadow: 0 0.25rem 0.5rem rgba($black, 0.2) !default;

$popover-header-bg: darken($popover-bg, 3%) !default;
$popover-header-color: $headings-color !default;
$popover-header-padding-y: 0.5rem !default;
$popover-header-padding-x: 0.75rem !default;

$popover-body-color: $body-color !default;
$popover-body-padding-y: $popover-header-padding-y !default;
$popover-body-padding-x: $popover-header-padding-x !default;

$popover-arrow-width: 1rem !default;
$popover-arrow-height: 0.5rem !default;
$popover-arrow-color: $popover-bg !default;

$popover-arrow-outer-color: fade-in($popover-border-color, 0.05) !default;

// Toasts

// scss-docs-start toast-variables
$toast-max-width: 350px !default;
$toast-padding-x: 16px;
$toast-padding-y: 16px;
$toast-font-size: 0.875rem !default;
$toast-color: palette(gray100);
$toast-background-color: $white;
$toast-border-width: 0;
$toast-border-color: var(--#{$prefix}border-color-translucent) !default;
$toast-border-radius: 8px;
$toast-box-shadow: 0px 6px 24px rgba(206, 212, 218, 0.3);
$toast-spacing: 12px;

$toast-header-color: null;
$toast-header-background-color: null;
$toast-header-border-color: rgba($black, 0.05) !default;
// scss-docs-end toast-variables

// Badges

$badge-font-size: 14px;
$badge-font-weight: 500;
$badge-color: $white !default;
$badge-padding-y: 2px;
$badge-padding-x: 16px;
$badge-border-radius: 40px;

$badge-transition: $btn-transition !default;
$badge-focus-width: $input-btn-focus-width !default;

// Use a higher than normal value to ensure completely rounded edges when
// customizing padding or font-size on labels.

// Modals

// Offcanvas
// scss-docs-start offcanvas-variables
$offcanvas-padding-y: 40px;
$offcanvas-padding-x: 40px;
$offcanvas-horizontal-width: 600px;
$offcanvas-vertical-height: 30vh !default;
$offcanvas-transition-duration: 0.3s !default;
$offcanvas-border-color: transparent;
$offcanvas-border-width: 0;
$offcanvas-title-line-height: 0;
$offcanvas-bg-color: palette(white);
$offcanvas-color: $body-color;
$offcanvas-box-shadow: 0px 6px 24px rgba($black, 0.2);
$offcanvas-backdrop-bg: palette(gray100);
$offcanvas-backdrop-opacity: 0.5;
// scss-docs-end offcanvas-variables

// Padding applied to the modal body
$modal-inner-padding: 0;

// Margin between elements in footer, must be lower than or equal to 2 * $modal-inner-padding
$modal-footer-margin-between: 0.5rem !default;

$modal-dialog-margin: 15px;
$modal-dialog-margin-y-sm-up: $modal-dialog-margin;

$modal-title-line-height: $line-height-base !default;

$modal-content-color: palette(black);
$modal-content-bg: $white !default;
$modal-content-border-color: rgba($black, 0.2) !default;
$modal-content-border-width: $border-width !default;
$modal-content-border-radius: 12px;
$modal-content-inner-border-radius: subtract(
  $modal-content-border-radius,
  $modal-content-border-width
) !default;
$modal-content-box-shadow-xs: 0px 6px 24px rgba(palette(gray40), 0.3);
$modal-content-box-shadow-sm-up: 0px 6px 24px rgba(palette(gray40), 0.3);

$modal-backdrop-bg: $black !default;
$modal-backdrop-opacity: 0.5 !default;
$modal-header-border-color: $border-color !default;

$modal-footer-bg: null !default;
$modal-footer-border-color: $modal-header-border-color !default;
$modal-header-border-width: $modal-content-border-width !default;
$modal-footer-border-width: $modal-header-border-width !default;
$modal-header-padding-y: 0;
$modal-header-padding-x: 0;
$modal-header-padding: $modal-header-padding-y $modal-header-padding-x !default; // Keep this for backwards compatibility

$modal-xl: 1160px;
$modal-lg: 880px;
$modal-md: 600px;
$modal-sm: 440px;

$modal-fade-transform: translate(0, -50px) !default;
$modal-show-transform: none !default;
$modal-transition: transform 0.3s ease-out !default;
$modal-scale-transform: scale(1.02) !default;

// Alerts
//
// Define alert colors, border radius, and padding.

$alert-padding-y: 0.75rem !default;
$alert-padding-x: 1.25rem !default;
$alert-margin-bottom: 1rem !default;
$alert-border-radius: $border-radius !default;
$alert-link-font-weight: $font-weight-bold !default;
$alert-border-width: $border-width !default;

$alert-bg-level: -10 !default;
$alert-border-level: -9 !default;
$alert-color-level: 6 !default;

// Progress bars

$progress-height: 1rem !default;
$progress-font-size: $font-size-base * 0.75 !default;
$progress-bg: palette(gray200) !default;
$progress-border-radius: $border-radius !default;
$progress-box-shadow: inset 0 0.1rem 0.1rem rgba($black, 0.1) !default;
$progress-bar-color: $white !default;
$progress-bar-bg: $primary !default;
$progress-bar-animation-timing: 1s linear infinite !default;
$progress-bar-transition: width 0.6s ease !default;

// List group

$list-group-color: null !default;
$list-group-bg: $white !default;
$list-group-border-color: rgba($black, 0.125) !default;
$list-group-border-width: $border-width !default;
$list-group-border-radius: $border-radius !default;

$list-group-item-padding-y: 0.75rem !default;
$list-group-item-padding-x: 1.25rem !default;

$list-group-hover-bg: palette(gray100) !default;
$list-group-active-color: $component-active-color !default;
$list-group-active-bg: $component-active-bg !default;
$list-group-active-border-color: $list-group-active-bg !default;

$list-group-disabled-color: palette(gray60) !default;
$list-group-disabled-bg: $list-group-bg !default;

$list-group-action-color: palette(gray70) !default;
$list-group-action-hover-color: $list-group-action-color !default;

$list-group-action-active-color: $body-color !default;
$list-group-action-active-bg: palette(gray200) !default;

// Image thumbnails

$thumbnail-padding: 0.25rem !default;
$thumbnail-bg: $body-bg !default;
$thumbnail-border-width: $border-width !default;
$thumbnail-border-color: palette(gray30) !default;
$thumbnail-border-radius: $border-radius !default;
$thumbnail-box-shadow: 0 1px 2px rgba($black, 0.075) !default;

// Figures

$figure-caption-font-size: 90% !default;
$figure-caption-color: palette(gray60) !default;

// Breadcrumbs

$breadcrumb-font-size: null !default;

$breadcrumb-padding-y: 0.75rem !default;
$breadcrumb-padding-x: 1rem !default;
$breadcrumb-item-padding: 0.5rem !default;

$breadcrumb-margin-bottom: 1rem !default;

$breadcrumb-bg: palette(gray200) !default;
$breadcrumb-divider-color: palette(gray60) !default;
$breadcrumb-active-color: palette(gray60) !default;
$breadcrumb-divider: quote('/') !default;

$breadcrumb-border-radius: $border-radius !default;

// Carousel

$carousel-control-color: $white !default;
$carousel-control-width: 15% !default;
$carousel-control-opacity: 0.5 !default;
$carousel-control-hover-opacity: 0.9 !default;
$carousel-control-transition: opacity 0.15s ease !default;

$carousel-indicator-width: 30px !default;
$carousel-indicator-height: 3px !default;
$carousel-indicator-hit-area-height: 10px !default;
$carousel-indicator-spacer: 3px !default;
$carousel-indicator-active-bg: $white !default;
$carousel-indicator-transition: opacity 0.6s ease !default;

$carousel-caption-width: 70% !default;
$carousel-caption-color: $white !default;

$carousel-control-icon-width: 20px !default;

$carousel-control-prev-icon-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' fill='#{$carousel-control-color}' width='8' height='8' viewBox='0 0 8 8'><path d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/></svg>") !default;
$carousel-control-next-icon-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' fill='#{$carousel-control-color}' width='8' height='8' viewBox='0 0 8 8'><path d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/></svg>") !default;

$carousel-transition-duration: 0.6s !default;
$carousel-transition: transform $carousel-transition-duration ease-in-out !default; // Define transform transition first if using multiple transitions (e.g., `transform 2s ease, opacity .5s ease-out`)

// Spinners

$spinner-width: 1.5rem !default;
$spinner-height: $spinner-width !default;
$spinner-vertical-align: -0.125em !default;
$spinner-border-width: 0.15em;
$spinner-animation-speed: 0.75s !default;

$spinner-width-sm: 1rem !default;
$spinner-height-sm: $spinner-width-sm !default;
$spinner-border-width-sm: 0.2em !default;

// Close

// scss-docs-start close-variables
$btn-close-width: 1.334em;
$btn-close-height: $btn-close-width !default;
$btn-close-padding-x: 0.25em !default;
$btn-close-padding-y: $btn-close-padding-x !default;
$btn-close-color: palette(gray60);
$btn-close-bg: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath d='M4 20 20 4M4 4l16 16' stroke='#{$btn-close-color}' stroke-width='2' stroke-linejoin='round'/%3E%3C/svg%3E");
$btn-close-focus-shadow: none;
$btn-close-opacity: 0.5 !default;
$btn-close-hover-opacity: 0.75 !default;
$btn-close-focus-opacity: 1 !default;
$btn-close-disabled-opacity: 0.25 !default;
$btn-close-white-filter: invert(1) grayscale(100%) brightness(200%) !default;
// scss-docs-end close-variables

// Code

$code-font-size: 87.5% !default;
$code-color: palette(pinkb) !default;

$kbd-padding-y: 0.2rem !default;
$kbd-padding-x: 0.4rem !default;
$kbd-font-size: $code-font-size !default;
$kbd-color: $white !default;
$kbd-bg: palette(gray300) !default;

$pre-color: palette(gray300) !default;
$pre-scrollable-max-height: 340px !default;

// Utilities

$displays: none, inline, inline-block, block, table, table-row, table-cell, flex,
  inline-flex !default;
$overflows: auto, hidden !default;
$positions: static, relative, absolute, fixed, sticky !default;

// Printing

$print-page-size: a3 !default;
$print-body-min-width: map-get($grid-breakpoints, 'lg') !default;
