@import '~styles/palette';
@import '~styles/mixins';

.wrap {
  padding-top: 65px;
  margin-top: auto;

  &.legacyDashboard {
    padding-top: 0;
  }
}

.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100px;
  padding-right: clamp-builder(0, 150);
  font-size: 14px;
  color: palette(gray60);
  border-top: 1px solid palette(gray20);
}

.menu {
  display: grid;
  grid-auto-flow: column;
  column-gap: 24px;

  a {
    text-decoration: underline;

    &:hover,
    &:focus {
      text-decoration: none;
    }
  }
}
