@import '~styles/palette';

.arrow {
  &.open {
    transform: rotate(180deg);
  }
}

.search {
  color: palette(gray50);
}
