@import '~styles/palette';

.login {
  display: grid;
  place-content: center;
  padding-top: 80px;
  text-align: center;

  &_box {
    width: 440px;
    padding: 42px 40px 0;
    margin-top: 27px;
    background-color: palette(white);
    border-radius: 12px;
    box-shadow: 0 6px 24px rgba(palette(gray40), 30%);

    &_bottom {
      padding: 24px 0 32px;
      margin-top: 56px;
      border-top: 1px solid palette(gray20);

      &--reset {
        margin-top: 39px;
      }
    }
  }

  &_bottom {
    margin-top: 47px;
  }

  h2 {
    color: palette(green70);
  }

  .medallia {
    position: relative;
    padding-top: 20px;
    margin-top: 20px;
    border-top: 1px solid palette(gray200);

    &::before {
      position: absolute;
      top: -7px;
      left: 50%;
      padding: 0 12px;
      font-size: 14px;
      line-height: 1.14;
      color: palette(gray70);
      content: 'Or';
      background-color: palette(white);
      transform: translateX(-50%);
    }

    a {
      justify-content: center;
      padding: 13px 0;
    }
  }

  .error--email {
    display: inline-block;
    font-weight: 500;
    color: palette(orange60);

    &:hover {
      color: darken(palette(orange60), 5);
    }
  }
}
