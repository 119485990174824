@import '~styles/palette';

.option {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 48px;
  padding: 12px;
  color: palette(gray80);
  cursor: pointer;
  background-color: transparent;
  border-bottom: 1px solid palette(gray20);
  transition: background-color 250ms;

  &:hover {
    color: palette(gray100);
    background-color: palette(gray10);

    button {
      background-color: palette(gray20);
    }
  }

  &:last-of-type {
    border-bottom: none;
  }

  &.nonBuyer {
    font-weight: 500;
    background-color: palette(gray20);
    border-color: palette(gray30);

    &:hover {
      color: palette(gray80);
      background-color: palette(gray10);
    }
  }
}

.optionRight {
  display: flex;
  align-items: center;
}

.optionCheck {
  margin-left: 12px;
  color: palette(green70);
}
