.legend--dbclick {
  .highcharts-legend-item .highcharts-graph:not(:only-child) {
    display: inline;
  }
}

.label--capitalize {
  .highcharts-xaxis-labels span::first-letter {
    text-transform: uppercase;
  }
}

[data-highcharts-chart],
.highcharts-container {
  overflow: visible !important;
}

.highcharts-container {
  z-index: auto !important;
}

.highcharts-xaxis-labels {
  z-index: 3;
}

// add extra space for box-shadow at the bottom of long tooltips
.highcharts-tooltip > span {
  &::after {
    position: absolute;
    top: 0;
    width: 1px;
    height: calc(100% + 10px);
    content: '';
  }
}
